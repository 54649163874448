<!--
  简单查询条件
  @author GongLiHai
-->
<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="48">
        <a-col v-for="searchItem in showSearch" :md="8" :sm="24">
          <a-form-item :label="searchItem.name" :prop="searchItem.field">
            <!-- 下拉框 -->
            <api-select v-if="searchItem.type == 'select'" :api="searchItem.api" :option="searchItem.option"
              :nameMapping="searchItem.nameMapping" :placeholder="'请选择' + searchItem.name"
              v-model="queryParam[searchItem.field]"></api-select>
            <!-- 输入框 -->
            <a-input v-else v-model="queryParam[searchItem.field]" :placeholder="'请输入' + searchItem.name" allow-clear />
          </a-form-item>
        </a-col>

        <!-- 默认隐藏的查询条件 -->
        <template v-if="hiddenSearch.length && advanced">
          <a-col v-for="searchItem in hiddenSearch" :md="8" :sm="24">
            <a-form-item :label="searchItem.name" :prop="searchItem.field">
              <!-- 下拉框 -->
              <api-select v-if="searchItem.type == 'select'" :api="searchItem.api" :option="searchItem.option"
                :nameMapping="searchItem.nameMapping" :placeholder="'请选择' + searchItem.name"
                v-model="queryParam[searchItem.field]"></api-select>
              <!-- 输入框 -->
              <a-input v-else v-model="queryParam[searchItem.field]" :placeholder="'请输入' + searchItem.name" allow-clear />
            </a-form-item>
          </a-col>
        </template>

        <a-col :md="!advanced && 8 || 24" :sm="24">
          <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
            <a-button type="primary" @click="search"><a-icon type="search" />查询</a-button>
            <a-button style="margin-left: 8px" @click="reset"><a-icon type="redo" />重置</a-button>
            <a v-if="hiddenSearch.length" @click="advanced = !advanced" style="margin-left: 8px">
              {{ advanced ? '收起' : '展开' }}
              <a-icon :type="advanced ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import ApiSelect from "../form/ApiSelect.vue";

import Util from '../Util.js';
import { Log } from "../Log.js";

export default {
  components: {
    ApiSelect
  },
  props: {
    config: Array
  },
  data() {
    return {
      // 展开/收起
      advanced: false,
      // 查询条件
      queryParam: {}
    }
  },
  computed: {
    // 常显查询条件
    showSearch() {
      if (!this.config || !this.config.length) {
        return [];
      }
      return this.config.filter(item => item.show == undefined || item.show == null || item.show == true);
    },
    // 隐藏查询条件
    hiddenSearch() {
      if (!this.config || !this.config.length) {
        return [];
      }
      // 显示指定 false 才隐藏
      return this.config.filter(item => item.show == false);
    }
  },
  created() {
    this.initQueryParam();
  },
  methods: {
    // 初始化查询属性
    initQueryParam() {
      this.config.forEach(item => {
        // 创建响应式属性
        this.$set(this.queryParam, item.field, Util.functionDefaultValue(item.default, undefined));
      });
    },
    // 重置按钮点击
    reset() {
      this.config.forEach(item => {
        this.$set(this.queryParam, item.field, Util.functionDefaultValue(item.default, undefined));
      });
      Log.info("重置", this.queryParam);
      // 查询按钮点击
      this.$emit('search', this.queryParam);
    },
    // 查询按钮点击
    search() {
      Log.info("搜索", this.queryParam);
      // 事件抛出
      this.$emit('search', this.queryParam);
    }
  }
}
</script>