<!--
  对 ant a-swtich 进行包装, 可以接受 1 或 0
  @author GongLiHai
-->
<template>
  <a-switch v-model="thisValue" :checked-children="checkName ? checkName : '是'"
    :un-checked-children="unCheckName ? unCheckName : '否'" :disabled="disabled" @click="click" />
</template>
<script>

export default {
  name: 'SwitchPack',
  props: {
    checkName: '是',
    unCheckName: '否',
    value: 0,
    disabled: false
  },
  data() {
    return {
      thisValue: !!this.value
    }
  },
  methods: {
    click() {
      this.$emit('click', this.thisValue ? 1 : 0);
    }
  },
  watch: {
    value(newValue) {
      this.thisValue = !!newValue;
    },
    thisValue() {
      this.$emit('input', this.thisValue ? 1 : 0);
    }
  }
}
</script>