/* 工具
 * @author GongLiHai
 */

/**
 * 默认值获取函数, 默认值可能是个函数, 执行函数, 为 null 或 undefined 返回 defaultValue
 * @param {*} functionDefaultValue 默认值函数
 * @param {*} defaultValue  默认值
 * @returns 
 */
function functionDefaultValue(functionDefaultValue, defaultValue) {
  if (functionDefaultValue == null || functionDefaultValue == undefined) {
    return defaultValue;
  }
  if (typeof functionDefaultValue == 'function') {
    return functionDefaultValue();
  }
  return functionDefaultValue;
}

/**
 * 缺席默认值
 * @param {*} val 值
 * @param {*} defVal 默认值 
 * @returns 
 */
function absentDefault(val, defVal) {
  return isAbsent(val) ? defVal : val;
}

/**
 * 是否缺席
 * @param {*} value 值
 * @returns true 缺席, false 有值
 */
function isAbsent(value) {
  return value == null || value == undefined;
}

export default {
  functionDefaultValue, absentDefault, isAbsent
};