<!--
  封装了请求的下拉框
  @author GongLiHai
-->
<template>
  <a-select v-model="thisValue" @change="change" :placeholder="placeholder" allowClear style="width: 100%">
    <a-select-option v-for="option in thisOptions" :value="option[valueMapping ? valueMapping : 'id']">
      {{ option[nameMapping ? nameMapping : 'name'] }}
    </a-select-option>
  </a-select>
</template>

<script>
import request from "@/utils/request.js";
export default {
  name: 'ApiSelect',
  props: {
    api: String,          // 选项数据来源请求地址, GET
    nameMapping: String,  // 选项显示名称字段映射
    valueMapping: String, // 选项值字段映射
    value: null,        // 本组件绑定value
    placeholder: String,  // 提示文字
    option: Array,       // 静态选项
  },
  data() {
    return {
      thisOptions: [], // 选项数据
      thisValue: undefined  // 下拉框绑定的值
    }
  },
  created() {
    this.rqeuestOptions();
  },
  methods: {
    /**
     * 请求获取选项数据
     */
    rqeuestOptions() {
      if (this.option) {
        this.thisOptions = this.option;
        return;
      }
      request({
        url: this.api,
        method: 'get',
      }).then(r => {
        this.thisOptions = r.data;
      })
    },
    /**
     * 下拉框值更新, 通知上层更新
     * @param {*} value 新值
     */
    change(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },
  watch: {
    /**
     * 上层值更新, 更新 下拉框值
     * @param {*} newValue 新值
     */
    value(newValue) {
      this.thisValue = newValue;
    }
  }
}
</script>
  
<style lang='less' scoped></style>