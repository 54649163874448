<template>
  <a-modal
    :width="600"
    :visible="visible"
    @cancel="handleCancel"
    :footer="null"
    style="padding: 0!important;">

    <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :options="playerOptions"
                  :playsinline="true"
                  @play="onPlayerPlay($event)"
                  @pause="onPlayerPause($event)"
    >
    </video-player>
  </a-modal>
</template>

<script>

    export default {
        name: "VideoModal",
        data() {
            return {
                title: "视频详情",
                visible: false,
                url: {
                    // imgerver: window._CONFIG['fileURL'],
                },
                playerOptions: {
                    // videojs options
                    autoplay: false, //如果true,浏览器准备好时开始回放。
                    muted: false,// 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    language: 'zh-CN',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",
                        src: ""
                    }],
                    poster: "",
                    width: document.documentElement.clientWidth,
                    notSupportedMessage: '此视频暂无法播放，请稍后再试' //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                }
            }
        },
        created() {
          this.player();
        },
        computed: {
            player() {
              console.log("this.$refscomputed.videoPlayer")
                console.log(this.$refs.videoPlayer)
                return this.$refs.videoPlayer.player;
            }
        },
        mounted() {
        },
        methods: {
            show(record) {
                // 视频路径
              // debugger
              let that = this;
              console.log(that.playerOptions)
                if(record.videoUrl.toString().includes("http")){
                  that.playerOptions.sources[0].src =  record.videoUrl;
                }
                // 视频封面
                if(record.videoUrl.toString().includes("http")){
                    this.playerOptions.poster = record.coverImage;
                }
                this.visible = true;
                // console.log(this.$refs.videoPlayer.player)
                player.play()
            },
            close() {
                // this.$refs.videoPlayer.player.pause();
                this.$emit('close');
                this.visible = false;
            },
            handleCancel() {
                this.close()
            },
            onPlayerPlay(player) {
                console.log("开始");
            },
            onPlayerPause(player) {
                console.log("暂停");
            }
        }
    }
</script>

<style lang="less">
.enter {
  cursor: pointer;
  position: absolute;
  top: 39%;
  left: 38%;
  font-size: 25px;
  color: #00A0E9;
}

.leave {
  cursor: pointer;
  position: absolute;
  top: 38%;
  left: 40%;
  font-size: 22px;
  color: #00A0E9;
}
</style>