<!--
  友好时间
  @author GongLiHai
-->
<template>
  <span style="cursor:pointer" @click="isFriend = !isFriend">{{ isFriend ? friendTime : time }}</span>
</template>
<script>
import moment from 'moment'
export default {
  name: 'FriendTime',
  props: {
    time: String,  // 'YYYY-MM-DD HH:mm:ss' 格式的时间
  },
  data() {
    return {
      isFriend: true
    };
  },
  computed: {
    friendTime() {
      if (this.time) {
        return moment(this.time, 'YYYY-MM-DD HH:mm:ss').fromNow();
      }
      return "";
    }
  }
}
</script>