<!--
  简单表格工具按钮
  @author GongLiHai
-->
<template>
  <div class="table-operations">
    <template v-for="buttonItem in config">
      <!-- 解决 v-hasPermi 为空报错问题, 此处判断 权限是否存在 -->
      <a-button v-if="buttonItem.auth && buttonItem.auth.length" v-hasPermi="buttonItem.auth" :type="buttonItem.color"
        :disabled="computeButtonDisabled(buttonItem)" @click="buttonClick(buttonItem, $event)">
        <a-icon :type="buttonItem.icon" /> {{ buttonItem.name }}
      </a-button>
      <a-button v-else :type="buttonItem.color" :disabled="computeButtonDisabled(buttonItem)"
        @click="buttonClick(buttonItem, $event)">
        <a-icon :type="buttonItem.icon" /> {{ buttonItem.name }}
      </a-button>
    </template>
    <!-- 表格设置 -->
    <!-- 有bug先注释掉 -->
    <!-- <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns" :refresh-loading="loading"
      @refresh="refresh" /> -->
  </div>
</template>
<script>
import { Log } from "../Log.js";

export default {
  name: 'ToolButton',
  props: {
    // 按钮配置
    config: Array,
    // 表格加载状态
    loading: Boolean,
    // 表格大小, 默认, 中等, 紧凑
    tableSize: String,
    // 列
    columns: Array,
    // 表格选中行
    tableSelectRows: Array
  },
  methods: {
    // 刷新点击
    refresh() {
      this.$emit('refresh');
    },
    // 按钮点击
    buttonClick(buttonItem, $event) {
      Log.info('操作按钮', buttonItem)
      this.$emit('click', buttonItem, $event);
    },
    // 计算按钮禁用状态
    computeButtonDisabled(buttonItem) {
      if (buttonItem.single == undefined && buttonItem.multiple == undefined) {
        return false;
      }
      // 单选且选中数量不为1 禁用 或 多选且选中数量为0 禁用
      return (buttonItem.single && this.tableSelectRows.length != 1) ||
        (buttonItem.multiple && this.tableSelectRows.length == 0)
    },
  }
}
</script>