<!--
  多图上传
  @author GongLiHai
-->
<template>
  <div class="clearfix">
    <a-upload list-type="picture-card" :file-list="fileList" @preview="handlePreview" @change="handleChange"
      :customRequest='fileUploadRequest' :beforeUpload='fileBeforeUpload'>
      <div v-if="maxCount != 0 && fileList.length < maxCount">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传
        </div>
      </div>
    </a-upload>
    <a-modal :visible="preview.visible" :footer="null" @cancel="preview.visible = false">
      <img alt="example" style="width: 100%" :src="preview.url" />
    </a-modal>
    <div v-if="maxCount != 0">最多上传 {{ maxCount }} 张</div>
  </div>
</template>
<script>
import UploadFileToOSS from '@/utils/upload-file-to-oss.js';
import { Log } from "../Log.js";
export default {
  props: {
    maxCount: {
      type: Number,
      default: 3
    },
    value: {
      type: String,
    },
    filePrefix: {
      type: String,
      default: '',              // 文件/图片访问前缀
    }
  },
  data() {
    return {
      preview: { // 预览弹窗
        visible: false, // 弹窗显示控制
        url: ''         //  地址
      },
      fileList: [],
    };
  },
  methods: {
    /**
     * 处理图片预览
     * @param {*} file 
     */
    handlePreview(file) {
      if (!file.url) {
        file.url = file.originFileObj.ossUrl;
      }
      this.preview.url = file.url;
      this.preview.visible = true;
    },
    /**
     * 图片变更事件
     * @param {*} option 
     */
    handleChange(option) {
      this.fileList = option.fileList;
      if (option.file.status == 'uploading') {
        return;
      }
      let value = [];
      for (const item of option.fileList) {
        if (item.ossName) {
          value.push(item.ossName);
        }
        if (item.originFileObj && item.originFileObj.ossName) {
          value.push(item.originFileObj.ossName);
        }
      }
      value = value.join(",");
      this.$emit('input', value);
      this.$emit('change', value);
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(fileInfo) {
      return new Promise((resolve, reject) => {
        if (!fileInfo.type.includes('image')) {
          this.$message.warning('请上传图片');
          reject();
        } else {
          resolve();
        }
      });
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(fileInfo) {
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = this.filePrefix + ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
  },
  watch: {
    /**
     * 监听 props 绑定 value变更, 更新 本地 fileList
     * @param {string} newVal 新值
     */
    value(newVal) {
      if (!newVal) {
        this.fileList = [];
        return;
      }
      this.fileList = newVal.split(",").map((item, index) => {
        return {
          uid: -index,
          name: item,
          status: 'done',
          url: this.filePrefix + item,
          thumbUrl: this.filePrefix + item,
          ossName: item
        }
      });
    }
  }
};
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>