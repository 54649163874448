<!--
  列插槽类型包装
  @author GongLiHai
-->
<template>
  <!-- 单图片 -->
  <img-preview v-if="computedColType == 'img'" :size="64" :src="ossFullUrl(record[col.field])" />

  <!-- 头像 -->
  <img-preview v-else-if="computedColType == 'avatar'" :size="32" shape="square" :src="ossFullUrl(record[col.field])"
    :errorIcon="'user'" />

  <!-- 多图片 -->
  <div v-else-if="computedColType == 'imgs' && record[col.field]">
    <!-- 解决阿里云视频封面问题 -->
    <template v-if="record[col.field].includes('x-oss-process=video/snapshot')">
      <img-preview :size="64" :src="ossFullUrl(record[col.field])" />
    </template>
    <template v-else>
      <img-preview v-for="item in record[col.field].split(',')" :src="ossFullUrl(item)" :size="64"
        style="margin-right: 8px;" />
    </template>
  </div>

  <!-- 文件类型 -->
  <a v-else-if="computedColType == 'file' && record[col.field]" :href="ossFullUrl(record[col.field])" target="_blank">
    {{ record[col.nameField] }}
  </a>

  <!-- 开关 -->
  <switch-pack v-else-if="computedColType == 'switch'" v-model="record[col.field]" :checkName="col.checkName"
    :unCheckName="col.unCheckName" @click="switchClick(col, record)"></switch-pack>

  <!-- 按钮类型 -->
  <div v-else-if="computedColType == 'button'">
    <template v-for="(button, index) in col.button">
      <template v-if="button.checkShow ? button.checkShow(record, button) : true">
        <a-divider v-if="index != 0" type="vertical" />
        <a @click='colButtonClick(button, record, $event)'>
          <a-icon v-if="button.icon" :type="button.icon" />
          {{ button.name }}
        </a>
      </template>
    </template>
  </div>

  <!-- 富文本 -->
  <div v-else-if="computedColType == 'richText'">
    <a-popover placement="topLeft">
      <div slot="content" style="max-width: 500px" v-html="record[col.field]"></div>
      <div style="width: 200px" class="truncate" v-html="record[col.field]"></div>
    </a-popover>
  </div>

  <!-- 长文本 -->
  <div v-else-if="computedColType == 'longText'">
    <a-popover placement="topLeft">
      <div slot="content" style="max-width: 500px">{{ record[col.field] }}</div>
      <div style="width: 200px" class="truncate">{{ record[col.field] }}</div>
    </a-popover>
  </div>

  <!-- 日期时间 -->
  <friend-time v-else-if="computedColType == 'datetime'" :time="record[col.field]"></friend-time>

  <!-- 标签 -->
  <div v-else-if="computedColType == 'tag'">
    <a-tag v-if="record[col.field] != null || record[col.field] != undefined" :color="col.tags[record[col.field]].color">
      {{ col.tags[record[col.field]].name }}
    </a-tag>
  </div>

  <!-- 视频 -->
  <div v-else-if="computedColType == 'video'">
    <video v-if="record[col.field]" :src="ossFullUrl(record[col.field])" style="height: 70px" controls></video>
  </div>

  <!-- 动态计算列类型 -->
  <col-pack v-else-if="computedColType == 'calculate'" :record="record" :text="text" :index="index" :col="col"
    :slotKey="slotKey" :colType="checkColType(record, index, col)" />

  <!-- 默认文本 -->
  <span v-else>{{ record[col.field] }}</span>
</template>
<script>
import SwitchPack from '../form/SwitchPack.vue';
import FriendTime from "./FriendTime.vue";
import ImgPreview from "./ImgPreview.vue";

import request from '@/utils/request'
import { Log } from "../Log.js";

export default {
  name: 'ColPack',
  components: { FriendTime, SwitchPack, ImgPreview },
  props: {

    record: Object, // 行数据
    text: null, // 单元格内容
    index: null, // 行号
    col: null, // 列配置
    slotKey: null, // 插槽 key
    colType: null // 列类型 (自身递归调用会传动态计算后的)
  },
  data() {
    return {}
  },
  computed: {
    // 计算列类型
    computedColType() {
      if (this.colType) {
        return this.colType;
      }
      return this.col.type;
    }
  },
  methods: {
    // 返回 oss 全路径
    ossFullUrl(path) {
      console.log("path============")
      console.log(path)
      return path;
    },
    // 开关点击事件
    switchClick(colConfig, record) {
      const value = record[colConfig.field];
      request({
        url: colConfig.api,
        method: 'post',
        data: { id: record.id, [colConfig.field]: !value }
      }).then(response => {
        this.$message.success(
          '修改成功'
        )
      }).catch(() => {
        // 失败, 取消修改值
        record[colConfig.field] = value;
      })
    },
    // 表格操作点击事件
    colButtonClick(col, rowData, event) {
      Log.info("列操作按钮", '列配置', col, '行数据', rowData);
      this.$emit('colButtonClick', col, rowData, event);
    },
    // 动态列类型安全校验
    checkColType(record, index, col) {
      if (!col.getType) {
        return null;
      }
      const colType = col.getType(record, index, col);
      if (colType == 'calculate') {
        throw new Error('动态列类型不能返回 "calculate", 请检查列配置 col.getType 函数返回');
      }
      return colType;
    }
  },
}
</script>

<style scoped>
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>