<!-- 图片包装
  已有功能:
    1. 点击全屏预览
    2. 自定义大小, size 大小值, sizeuUnit 单位
    3. errorIcon 加载失败图标
-->
<template>
  <span class="ant-avatar ant-avatar-square" :class="isError ? 'ant-avatar-icon' : 'ant-avatar-image'" :style="sizeStyle">

    <a-icon v-if="isError" :type='errorIcon' />

    <a-popover v-else>
      <template slot="content">
        <img :src="src" @error="onError" width="300px"
          style="border-radius: 5px;box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);" />
      </template>
      <img :src="src" @error="onError" />
    </a-popover>

  </span>
</template>
<script>
export default {
  name: 'ImgPreview',
  props: {
    // 预览分组 preview 'vue-photo-preview'
    group: {
      type: String,
      default() {
        // 这里取随机
        return Math.random() + ""
      }
    },
    // 图片 src
    src: {
      type: String,
      default: ''
    },
    // 大小
    size: {
      type: Number,
      default: 32
    },
    // 大小单位, 默认 px
    sizeuUnit: {
      type: String,
      default: 'px'
    },
    // 错误图标
    errorIcon: {
      type: String,
      default: 'file-image'
    },
  },
  computed: {
    sizeStyle() {
      return {
        width: `${this.size}${this.sizeuUnit}`,
        height: `${this.size}${this.sizeuUnit}`,
        fontSize: `${this.size / 2}${this.sizeuUnit}`,
        lineHeight: `${this.size}${this.sizeuUnit}`,
      }
    }
  },
  data() {
    return {
      isError: false,
    }
  },
  methods: {
    onError() {
      this.isError = true;
    },
  },
  watch: {
    // 监听 src 改变, 修改 错误状态, 解决 组件复用, 错误图片未更改问题
    src() {
      this.isError = false;
    }
  }
}
</script>
<style></style>